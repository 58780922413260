import { NavLink } from "react-router-dom";
import { useState } from "react";
import urlConst from "../urlConsts";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./Sidebar.css";
import { useEffect } from "react";
import Logo from "../logo/Logo.svg";
import { Link } from "react-router-dom";
const Sidebar = () => {
  const location = useLocation();
  const organization = useSelector(
    (state) => state.organizationDetails.organization
  );
  const {
    userLogin: {
      userInfo: { data },
    },
  } = useSelector((state) => state);

  // Function to check active path
  function useActivePath(paths) {
    return paths.some((path) => location.pathname.includes(path));
  }

  const isActiveApps = useActivePath(["/apps", "/app"]);
  const isActiveUsers = useActivePath(["/users", "/user"]);
  const isActiveOrganizations = useActivePath([
    "/organizations",
    "/organization",
  ]);
  const organizationuserRole = organization?.user_role;

  // Sidebar visibility state
  const [sidebarVisible, setSidebarVisible] = useState(
    localStorage.getItem("sidebarVisible") === "true"
  );
  const closeSidebar = () => {
    setSidebarVisible(false);
    localStorage.setItem("sidebarVisible", "false");
    window.dispatchEvent(new Event("storage"));
  };

  // Close sidebar on clicking outside or scrolling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarVisible && !event.target.closest(".sidebar")) {
        closeSidebar();
      }
    };

    const handleScroll = () => {
      if (sidebarVisible) {
        closeSidebar();
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarVisible]);

  // Sync sidebar state across tabs
  useEffect(() => {
    const handleStorageChange = () => {
      const isVisible = localStorage.getItem("sidebarVisible") === "true";
      setSidebarVisible(isVisible);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <nav
        className={`sidebar ${sidebarVisible ? "sidebar-visible" : ""}`}
        id="sidebar"
      >
        <div className="d-none logo">
          <Link
            className="navbar-brand brand-logo"
            style={{ textAlign: "center" }}
            to="/apps"
          >
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <ul className="nav">
          {urlConst === "test" && (
            <li className="nav-item" onClick={closeSidebar}>
              <NavLink className="nav-link" to="/dashboard">
                <i className="fa fa-home menu-icon" aria-hidden="true"></i>
                <span className="menu-title">Dashboard</span>
              </NavLink>
            </li>
          )}

          {data.role !== "Super_Admin" &&
          organizationuserRole === "Admin" &&
          location.pathname !== "/organization/create" ? (
            <>
              <li
                className={`nav-item ${isActiveApps ? "active" : ""}`}
                onClick={closeSidebar}
              >
                <NavLink
                  className={`nav-link ${isActiveApps ? "active" : ""}`}
                  to="/apps"
                >
                  <i className="bi bi-box-seam menu-icon"></i>
                  <span className="menu-title">Apps</span>
                </NavLink>
              </li>

              <li
                className={`nav-item ${isActiveUsers ? "active" : ""}`}
                onClick={closeSidebar}
              >
                <NavLink
                  className={`nav-link ${isActiveUsers ? "active" : ""}`}
                  to="/users"
                >
                  <i className="bi bi-person-add menu-icon"></i>
                  <span className="menu-title">Users</span>
                </NavLink>
              </li>
            </>
          ) : data.role !== "Super_Admin" &&
            organizationuserRole !== "Admin" &&
            location.pathname !== "/organization/create" ? (
            <li
              className={`nav-item ${isActiveApps ? "active" : ""}`}
              onClick={closeSidebar}
            >
              <NavLink
                className={`nav-link ${isActiveApps ? "active" : ""}`}
                to="/apps"
              >
                <i className="bi bi-box-seam menu-icon"></i>
                <span className="menu-title">Apps</span>
              </NavLink>
            </li>
          ) : null}

          {location.pathname === "/organization/create" && (
            <li
              className={`nav-item ${isActiveApps ? "active" : ""}`}
              onClick={closeSidebar}
            >
              <NavLink
                className={`nav-link ${isActiveApps ? "active" : ""}`}
                to="#"
              >
                <i className="bi bi-box-seam menu-icon"></i>
                <span className="menu-title">Organization</span>
              </NavLink>
            </li>
          )}

          {urlConst === "test" && (
            <li className="nav-item" onClick={closeSidebar}>
              <NavLink className="nav-link" to="/products">
                <i className="fa fa-cube menu-icon" />
                <span className="menu-title">Products</span>
              </NavLink>
            </li>
          )}

          {data.role === "Super_Admin" && (
            <li
              className={`nav-item ${isActiveOrganizations ? "active" : ""}`}
              onClick={closeSidebar}
            >
              <NavLink
                className={`nav-link ${isActiveOrganizations ? "active" : ""}`}
                to="/organizations"
              >
                <i className="bi bi-person-add menu-icon"></i>
                <span className="menu-title">Organizations</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
