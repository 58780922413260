import React from 'react';

const Navbar = ({ env_type }) => {
  return (
    <header>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className='container'>
            <a className="navbar-brand" href="/#"><img src="assets/logo/Logo.svg" alt="logo" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    
                    <li className="nav-item">
                        <a className="nav-link getLink" href="/register">🚀 Start free</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
  );
};

export default Navbar;
