import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Apps from "./Apps";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";

import { listApps } from "../../../redux/actions/AppActions";

import "./apps.css";
import { Circles } from "react-loader-spinner";

const AppListDetails = (props) => {
  const { from, organizationId } = props;
  const dispatch = useDispatch();

  const role = useSelector((state) => state.userLogin.userInfo.data.role);
  const appList = useSelector((state) => state.appList);
  const organization = useSelector(
    (state) => state.organizationDetails.organization
  );
  const { apps, numOfPages, sortBy, requestedCount, existingCount } = appList;
  const organizationIds = organizationId
    ? organizationId
    : organization?.organization_id;
  const organizationRoles = organization?.user_role;
  let pageNum = 1;
  const existingAppsCount = existingCount;
  const requestedAppsCount = requestedCount;

  const [activeTab, setActiveTab] = useState("Existing");
  const [currentPage, setCurrentPage] = useState(0);
  const [os, setOs] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [appsPerPage, setAppsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      listApps(
        pageNum,
        appsPerPage,
        sortBy,
        searchTerm,
        role,
        os,
        activeTab,
        from,
        organizationIds
      )
    )
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching apps:", error);
        setLoading(false);
      });
  }, [organizationIds]);

  const handleChangeTab = (value) => {
    setActiveTab(value);
    setLoading(true);
    dispatch(
      listApps(
        pageNum,
        appsPerPage,
        sortBy,
        searchTerm,
        role,
        os,
        value,
        from,
        organizationIds
      )
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching apps:", error);
        setLoading(false);
      });
  };

  const handlePageClick = (data) => {
    pageNum = data.selected + 1;
    setCurrentPage(data.selected);
    setLoading(true);
    dispatch(
      listApps(
        pageNum,
        appsPerPage,
        sortBy,
        searchTerm,
        role,
        os,
        activeTab,
        from,
        organizationIds
      )
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching apps:", error);
        setLoading(false);
      });
  };

  const handleFilterBy = (e) => {
    const filterByOs = e.target.value;
    setOs(filterByOs);
    setCurrentPage(0);
    dispatch(
      listApps(
        pageNum,
        appsPerPage,
        sortBy,
        searchTerm,
        role,
        filterByOs,
        activeTab,
        from,
        organizationIds
      )
    );
  };

  const handleIconClick = () => {
    const searchTerm = document.querySelector('input[name="search"]').value;
    handleSearch(searchTerm);
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    const delaySearchFunc = setTimeout(() => {
      setCurrentPage(0);
      setLoading(true);
      dispatch(
        listApps(
          pageNum,
          appsPerPage,
          sortBy,
          searchTerm || "",
          role,
          os,
          activeTab,
          from,
          organizationIds
        )
      )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching apps:", error);
          setLoading(false);
        });
    }, 1500);
    return () => clearTimeout(delaySearchFunc);
  };

  const handleItemsPerPageChange = (e) => {
    setAppsPerPage(Number(e.target.value));
    setCurrentPage(0);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="header">
            {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
            {from === "Apps" ? <h4 className="card-title">Apps</h4> : null}
          </div>
          {role === "Super_Admin" || "Member" || "Tester" ? (
            <Box
              sx={{ width: "100%", bgcolor: "#fff7e2", margin: "20px 0 0 0" }}
            >
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => handleChangeTab(newValue)}
                TabIndicatorProps={{ style: { backgroundColor: "#1F3BB3" } }}
              >
                <Tab
                  label={
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span className="app">Existing Apps</span>
                      <Badge
                        badgeContent={existingAppsCount}
                        className={`existingAppCount ${
                          activeTab === "Existing" ? "active-badge" : ""
                        }`}
                        sx={{ marginLeft: "15px" }}
                      />
                    </span>
                  }
                  value="Existing"
                  className={activeTab === "Existing" ? "active-tab" : ""}
                />
                <Tab
                  label={
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span className="app">Requested Apps</span>
                      <Badge
                        badgeContent={requestedAppsCount}
                        className={`existingAppCount ${
                          activeTab === "Requested" ? "active-badge" : ""
                        }`}
                        sx={{ marginLeft: "15px" }}
                      />
                    </span>
                  }
                  value="Requested"
                  className={activeTab === "Requested" ? "active-tab" : ""}
                />
              </Tabs>
            </Box>
          ) : null}

          {/* Content based on active tab */}
          {activeTab === "Existing" ? (
            <>
              {/* Filter and Add App Button */}
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="row mt-4 align-items-center">
                    {/* Filter By OS Dropdown */}
                    <div className="col-lg-4 col-md-6 mb-3">
                      <select
                        className="form-select"
                        aria-label="Filter By OS"
                        onChange={handleFilterBy}
                      >
                        <option value="">Filter By OS</option>
                        <option value="">All</option>
                        <option value="IOS">iOS</option>
                        <option value="Android">Android</option>
                      </select>
                    </div>

                    {/* Search Input with Icon */}
                    <div className="col-lg-4 col-md-6 mb-3">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Search - Write and Enter"
                          className="form-control"
                          name="search"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>

                    {/* Add App or New App Request Button */}
                    <div className="col-lg-4 col-md-12 mb-3 text-md-end text-center">
                      {organizationRoles === "Admin" &&
                      role !== "Super_Admin" ? (
                        <Link to="/app/add">
                          <button className="btn btn-primary">Add App</button>
                        </Link>
                      ) : (organizationRoles === "Member" ||
                          organizationRoles === "Tester") &&
                        role !== "Super_Admin" ? (
                        <Link to="/app/request">
                          <button className="btn btn-secondary">
                            New App Request
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* Existing Apps Table */}
              <div className="table-responsive">
                <table className="table table-hover apps-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="app-name">Name</th>
                      <th className="app-logo">Logo</th>
                      <th className="app-description">Description</th>
                      <th className="app-os">OS</th>

                      {/* Conditional rendering for "Apps" */}
                      {from === "Apps" && (
                        <th className="app-release">Builds</th>
                      )}

                      {/* Conditional rendering for Super_Admin role */}
                      {role === "Super_Admin" && (
                        <>
                          <th className="app-release">Status</th>
                          <th className="app-release">Builds</th>
                        </>
                      )}

                      {/* Conditional rendering for Admin role */}
                      {role !== "Super_Admin" &&
                        organizationRoles === "Admin" && (
                          <>
                            <th className="app-release text-center">Status</th>
                            <th className="app-action text-center">Action</th>
                          </>
                        )}
                    </tr>
                  </thead>

                  {/* Render Apps if they exist */}
                  {existingAppsCount > 0 ? (
                    <tbody>
                      {apps.map((app) => (
                        <Apps
                          app={app}
                          role={role}
                          from={from}
                          organizationId={organizationId}
                          key={app.id}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="6" className="text-center">
                          No Apps Found
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </>
          ) : role === "Super_Admin" ? (
            <>
              <div className="table-responsive">
                <table className="table table-hover requested-apps-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="app-name">Name</th>
                      <th className="app-logo">Logo</th>
                      <th className="app-description">Description</th>
                      <th className="app-requested">OS</th>
                      <th className="app-requested">Requested By</th>
                    </tr>
                  </thead>

                  {/* Render Requested Apps if they exist */}
                  {requestedAppsCount > 0 ? (
                    <tbody>
                      {apps.map((app) => (
                        <Apps app={app} key={app.id} />
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="6" className="text-center">
                          No Requested Apps Found
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </>
          ) : (
            <>
              {/* Requested Apps Table */}
              <div className="table-responsive">
                <table className="table table-hover requested-apps-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="app-name">Name</th>
                      <th className="app-logo">Logo</th>
                      <th className="app-description">Description</th>
                      <th className="app-os">OS</th>

                      {/* Conditional rendering for Admin role */}
                      {role !== "Super_Admin" &&
                        organizationRoles === "Admin" && (
                          <th className="app-requested">Requested By</th>
                        )}

                      {role !== "Super_Admin" &&
                        organizationRoles === "Admin" && (
                          <th className="app-action">Action</th>
                        )}
                    </tr>
                  </thead>

                  {/* Render Requested Apps if they exist */}
                  {requestedAppsCount > 0 ? (
                    <tbody>
                      {apps.map((app) => (
                        <Apps app={app} key={app.id} />
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="6" className="text-center">
                          No Requested Apps Found
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}

          {/* Pagination and Items Per Page */}
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={numOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={currentPage}
            />
            <div>
              <select
                className="form-select mt-3 mt-lg-0"
                aria-label="Items Per Page"
                onChange={handleItemsPerPageChange}
              >
                <option value="20">20 per page</option>
                <option value="40">40 per page</option>
                <option value="60">60 per page</option>
                <option value="80">80 per page</option>
                <option value="100">100 per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default AppListDetails;
