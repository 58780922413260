import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navbar from "./Navbar";
import logo from "../../logo/Logo.svg";

const PrivacyPolicy = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".section-content");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.2 }
    );

    sections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, []);

  const privacySections = [
    {
      title: "1. Information We Collect",
content: (
  <List>
    <ListItem>
      <ListItemText primary="We may collect the following types of information:" />
    </ListItem>
    <ListItem>
    <ListItemText primary={<b>1.1 Information You Provide to Us:</b>}/>
    </ListItem>
    <List>
      <ListItem>
        <ListItemText primary="Account Information: When you create an account, we may collect your name, email address, username, and password." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Feedback and Support: If you contact us for support or provide feedback, we may collect your contact information and any details you share with us." />
      </ListItem>
    </List>
    <ListItem>
      <ListItemText primary={<b>1.2 Information Collected Automatically:</b>}/>
    </ListItem>
    <List>
      <ListItem>
        <ListItemText primary="Usage Data: Information about your interactions with the Service, such as pages viewed, features used, and time spent on the platform." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Device Information: Details about the device you use to access the Service, including your IP address, browser type, operating system, and device identifiers." />
      </ListItem>
    </List>
    <ListItem>
      <ListItemText primary={<b>1.3 Cookies and Tracking Technologies:</b>}/>
    </ListItem>
    <List>
      <ListItem>
        <ListItemText primary="We use cookies, web beacons, and similar technologies to collect information about your use of the Service. You can manage your cookie preferences in your browser settings." />
      </ListItem>
    </List>
  </List>
),
    },
    {
      title: "2. How We Use Your Information",
            content: (
              <List>
                <ListItem>
                  <ListItemText primary="We use the information we collect for the following purposes:" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="To provide, operate, and improve the Service & User Experience." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="To communicate with you, including responding to inquiries and sending updates about the Service." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="To analyze usage trends and user behavior to enhance user experience." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="To comply with legal obligations and enforce our Terms of Use." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="To secure and show the right features on right moment in apps" />
                </ListItem>
              </List>
            ),
    },
    {
      title: "3. Sharing of Information",
            content: (
              <List>
                <ListItem>
                  <ListItemText primary="We do not sell your personal information to third parties. However, we may share your information in the following circumstances:" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={<><b>With Service Providers:</b> Third-party vendors who assist in operating and maintaining the Service, subject to confidentiality agreements.</>} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={<><b>For Legal Compliance:</b> When required by law or to protect our rights, privacy, safety, or property.</>} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={<><b>With Your Consent:</b> When you explicitly agree to share your information.</>} />

                </ListItem>
              </List>
            ),
    },
    {
      title: "4. Data Security",
      content:
        "We implement reasonable technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no data transmission or storage system can be guaranteed to be 100% secure.",
    },
    {
      title: "5. Your Choices and Rights",
            content: (
              <List>
                <ListItem>
                  <ListItemText primary="You have the following rights regarding your information:" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={<><b>Access and Update:</b> You can access and update your account information through your profile settings.</>} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={<><b>Delete Your Data:</b> You may request deletion of your account and personal data by contacting us at support@betaappstore.com.</>} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={<><b>Opt-Out:</b> You can opt out of receiving promotional communications by following the unsubscribe instructions in our emails.</>} />
                </ListItem>
              </List>
            ),
    },
    {
      title: "6. Retention of Data",
      content:
        "We retain your information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.",
    },
    {
      title: "7. Third-Party Links",
      content:
        "The Service may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. Please review their privacy policies before providing any personal information.",
    },
    {
      title: "8. Changes to This Privacy Policy",
      content:
        "We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised policy. Your continued use of the Service constitutes your acceptance of the updated Privacy Policy.",
    },
    {
      title: "12. Contact Us",
      content: (
        <>
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@betaappstore.com" className="text-blue-500 underline">support@betaappstore.com</a>
          <br />
          Thank you for trusting Beta Appstore. Your privacy is important to us!
        </>
      ),
    },
  ];

  return (
    <>
      <AppBar position="static" sx={{ background: "#ffb700" }}>
        <Navbar env_type="STAGING" />
        <Toolbar sx={{ flexGrow: 1, margin: "60px 0px 2px 0px" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: "'Noto Sans', sans-serif !important" }}>
            Privacy Policy
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 4 }}>
        {/* <Typography variant="h4" align="center" gutterBottom>
        Effective Date: 05th Jan, 2025
        </Typography> */}
        <Typography variant="body1" align="left" gutterBottom sx={{ flexGrow: 1, fontFamily: "'Noto Sans', sans-serif !important" }}>
          
        <b>Effective Date:</b> 05th Jan, 2025 
        <br/>
        Barquecon Technologies Pvt Ltd (“we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use Beta Appstore (“the Service”). Please read this Privacy Policy carefully. By using the Service, you agree to the collection and use of your information as described in this Privacy Policy.
        </Typography>
        {privacySections.map((section, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              p: 3,
              my: 2,
              borderLeft: "5px solid #ffb700",
              background: "#ffffff",
              fontFamily: "'Noto Sans', sans-serif !important",
              
            }}
            className="section-content"
          >
            <Typography variant="h5" sx={{ mb: 2, color: "#002855", fontFamily: "'Noto Sans', sans-serif !important" }}>
              {section.title}
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: "'Noto Sans', sans-serif !important" }}>{section.content}</Typography>
          </Paper>
        ))}
      </Container>

      <footer className="landingFooter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <img src={logo} alt="logo" className="footerLogo mr-3" />
                <p>
                  Copyright © 2025. All rights reserved to Barquecon Technologies
                  Pvt Ltd.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="d-flex justify-content-end">
                <a href="/terms-of-use">Terms of use</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PrivacyPolicy;
