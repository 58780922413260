import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import {useEffect, useState, useRef} from 'react';
import { Circles } from 'react-loader-spinner'; 
import UsersDetails from './UsersDetails';

const Users = () => {
    const [loading, setLoading] = useState(false); 

	return(
		<>
		    <div className="container-scroller">		        
                <Header/>		        
                <div className="container-fluid page-body-wrapper">
                    <Sidebar/>
                    <div className="main-panel" >
                        <div className="content-wrapper">
                            <div className="row" data-aos="zoom-in">
                                <div className="col-lg-12 grid-margin stretch-card" data-aos="fade-up">
                                    <UsersDetails from={"users"} organizationId ={null} />
                                </div>		                
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>		        
            </div> 
            {loading &&  
                <div className="fullscreen-loader">
                  <Circles color="#F1B621" height={80} width={80} />
                </div>
            }
		</>
	)
}

export default Users;