import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useHistory } from "react-router-dom";
import logo from "../../logo/Logo.svg";
import "./landingPage.css";
import "aos/dist/aos.css";
import AOS from "aos";
const LandingPage = () => {
  const history = useHistory();
  const env_type = process.env.REACT_APP_ENVIRIONMENT;

  const handleLogin = () => {
    history.push("/login");
  };

  const handleSignUp = () => {
    history.push("/register");
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate"); 
            observer.unobserve(entry.target); 
          }
        });
      },
      { threshold: 0.1 } 
    );

    const elementsToAnimate = document.querySelectorAll(".animateOnScroll");
    elementsToAnimate.forEach((element) => observer.observe(element));

    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, [anchorEl]);

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: "ease-in-out", 
      once: true, 
    });
  }, []);

  return (
    <>
      <AppBar color="inherit" sx={{ boxShadow: "none" }}>
        <Container>
          <Toolbar disableGutters>
            {/* Logo */}
            <a href="/#" className="navbar-brand">
              <img
                src="assets/logo/Logo.svg"
                alt="logo"
                style={{ height: "40px" }}
              />
            </a>

            {/* Desktop Navigation */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              <ul
                className="navbar-nav mr-auto"
                style={{
                  display: "contents",
                  alignItems: "center",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                {env_type === "STAGING" && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#priceDiv"
                      style={{
                        marginRight: "16px",
                        textDecoration: "none",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: 500,
                        fontFamily: '"Noto Sans", sans-serif',
                        transition: "all 0.3s ease", 
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#f1b621")}
                      onMouseLeave={(e) => (e.target.style.color = "#000")} 
                    >
                      Pricing
                    </a>
                  </li>
                )}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#aboutUs"
                    style={{
                      marginRight: "16px",
                      textDecoration: "none",
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: '"Noto Sans", sans-serif',
                      position: "relative",
                      transition: "all 0.3s ease", 
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#f1b621"; 
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#000";
                    }}
                  >
                    About Us
                  </a>
                </li>
              </ul>

              <ul
                className="navbar-nav ml-auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link getLink"
                    href="/register"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: '"Noto Sans", sans-serif',
                      transition: "all 0.3s ease", 
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#f1b621")}
                    onMouseLeave={(e) => (e.target.style.color = "#000")}
                  >
                    🚀 Start free
                  </a>
                </li>
              </ul>
            </Box>

            {/* Mobile Navigation */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                marginLeft: "auto",
              }}
            >
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>

          {/* Mobile Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                minWidth: "auto",
                background: "rgb(0, 0, 0)",
                borderRadius: 2,
              },
            }}
          >
            {env_type === "STAGING" && (
              <MenuItem onClick={handleMenuClose}>
                <a
                  className="nav-link"
                  href="#priceDiv"
                  style={{
                    textDecoration: "none",
                    color: "rgb(255 255 255)",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: '"Noto Sans", sans-serif',
                  }}
                >
                  Pricing
                </a>
              </MenuItem>
            )}
            <MenuItem onClick={handleMenuClose}>
              <a
                className="nav-link"
                href="#aboutUs"
                style={{
                  textDecoration: "none",
                  color: "rgb(255 255 255)",
                  fontSize: "15px",
                  fontWeight: 500,
                  fontFamily: '"Noto Sans", sans-serif',
                }}
              >
                About Us
              </a>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <a
                className="nav-link getLink"
                href="/register"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "15px",
                  fontWeight: 500,
                  fontFamily: '"Noto Sans", sans-serif',
                }}
              >
                🚀 Start free
              </a>
            </MenuItem>
          </Menu>
        </Container>
      </AppBar>
      <div className="landingPage">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="landingBox text-center text-lg-start">
                <h1 className="fadeIn animateOnScroll">Welcome to</h1>
                <h2 className="slideInRight animateOnScroll">Beta Appstore</h2>
                <p className="fadeInUp animateOnScroll">
                  The hub for innovators—create apps, upload builds, and
                  collaborate with your team. Streamline your development
                  process on one platform.
                </p>
                <div className="button-container d-flex flex-column flex-md-row justify-content-center justify-content-lg-start">
                  <button
                    onClick={handleLogin}
                    className="loginBtnCss fadeInUp mb-2 mb-md-0 me-md-2 animateOnScroll"
                  >
                    Sign In
                  </button>
                  <button
                    onClick={handleSignUp}
                    className="loginBtnCss fadeInUp animateOnScroll"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block animateOnScroll">
              <div className="welcomeImg text-center animateOnScroll">
                <img
                  src="assets/images/landing_Img.png"
                  alt="logo"
                  className="zoomIn img-fluid"
                  style={{ maxHeight: "400px", objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whiteWrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="headStyle" data-aos="fade-up">
                Beta Appstore excels at
              </h2>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-lg-2" data-aos="zoom-in">
              <div
                className="appBox"
                style={{
                  transition: "transform 0.3s, box-shadow 0.3s",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 15px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow = "none";
                }}
              >
                <img src="assets/images/apple.png" alt="logo" />
                <h3>iOS apps</h3>
                <h4>Swift and Objective-C</h4>
              </div>
            </div>

            <div
              className="col-lg-2 mt-4 mt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div
                className="appBox"
                style={{
                  transition: "transform 0.3s, box-shadow 0.3s",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 15px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow = "none";
                }}
              >
                <img src="assets/images/android.png" alt="logo" />
                <h3>Android apps</h3>
                <h4>Java, React Native, and Kotlin</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/windows.png" alt="logo" />
                                <h3>Windows apps</h3>
                                <h4>UWP, WPF and WinForms</h4>
                            </div>
                        </div> */}

      {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/react.png" alt="logo" />
                                <h3>React Native apps</h3>
                                <h4>iOS and Android</h4>
                            </div>
                        </div> */}

      {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/xamarin.png" alt="logo" />
                                <h3>Xamarin apps</h3>
                                <h4>iOS and Android</h4>
                            </div>
                        </div> */}

      {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/apps.png" alt="logo" />
                                <h3>Even more!</h3>
                                <h4>macOS, tvOS and Unity</h4>
                            </div>
                        </div> */}

      {/* </div>
                </div>
            </div> */}

      {/* <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>Connect your source control, automate your workflows, and manage app releases with ease.
                            </h2>
                            <p className='text-center pStyle'>Build in the cloud, run tests, and deploy updates to testers or app stores with full integration for popular repositories.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="whiteWrap">
        <div className="container">
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
              <img
                src="assets/images/start.jpeg"
                alt="Quick Start"
                className="w-100 img-fluid"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="200"
              />
            </div>

            {/* Text Section */}
            <div className="col-lg-5 col-md-12 text-center text-lg-start">
              <h2
                className="headStyleTwo"
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="300"
                style={{ fontWeight: "bold", color: "#333" }}
              >
                Quick Start, Maximum Efficiency
              </h2>
              <p
                className="pStyle"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{ color: "#555", fontSize: "1rem", lineHeight: "1.5" }}
              >
                Getting started is easy — Beta Appstore’s smart analyzer handles
                iOS, Android, and React Native builds, ensuring you’re ready to
                upload in no time.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grayWrap">
        <div className="container">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-lg-5 col-md-12 text-center text-lg-start">
              <h2
                className="headStyleTwo"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                style={{ fontWeight: "bold", color: "#333" }}
              >
                Enhanced Collaboration Tools
              </h2>
              <p
                className="pStyle"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
                style={{ color: "#555", fontSize: "1rem", lineHeight: "1.5" }}
              >
                Boost productivity with seamless collaboration. Invite team
                members, assign roles, and streamline app development under one
                unified platform. Stay informed with notifications sent to
                developers and testers whenever a new build is uploaded.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-lg-7 col-md-12 mt-4 mt-lg-0">
              <img
                src="assets/images/image1.png"
                alt="Collaboration Tools"
                className="w-100 img-fluid"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{
                  objectFit: "contain",
                  maxHeight: "100%",
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="whiteWrap">
        <div className="container">
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
              <img
                src="assets/images/smart_app.jpeg"
                alt="Smarter App Distribution"
                className="w-100 img-fluid"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="200"
                style={{
                  objectFit: "contain",
                  maxHeight: "100%",
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                }}
              />
            </div>

            {/* Text Section */}
            <div className="col-lg-5 col-md-12 text-center text-lg-start">
              <h2
                className="headStyleTwo"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
                style={{ fontWeight: "bold", color: "#333" }}
              >
                Smarter App Distribution
              </h2>
              <p
                className="pStyle"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{ color: "#555", fontSize: "1rem", lineHeight: "1.5" }}
              >
                Automate your releases with ease. Assign testers and let Beta
                Appstore deliver updates automatically every time your branch
                gets a new merge.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grayWrap">
        <div className="container">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-lg-5 col-md-12 text-center text-lg-start">
              <h2
                className="headStyleTwo"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                style={{ fontWeight: "bold", color: "#333" }}
              >
                One-Click OTA Installation
              </h2>
              <p
                className="pStyle"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
                style={{ color: "#555", fontSize: "1rem", lineHeight: "1.5" }}
              >
                Distribute builds directly to testers and stakeholders via
                over-the-air (OTA) updates. Provide iOS .ipa and Android .apk
                files with just a single click for streamlined user feedback.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-lg-7 col-md-12 mt-4 mt-lg-0">
              <img
                src="assets/images/ota.jpeg"
                alt="OTA Installation"
                className="w-100 img-fluid"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{
                  objectFit: "contain",
                  maxHeight: "100%",
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="whiteWrap">
        <div className="container">
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
              <img
                src="assets/images/image2.png"
                alt="Comprehensive Platform Support"
                className="w-100 img-fluid"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="200"
                style={{
                  objectFit: "cover",
                  maxHeight: "372px",
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                }}
              />
            </div>

            {/* Text Section */}
            <div className="col-lg-5 col-md-12 text-center text-lg-start">
              <h2
                className="headStyleTwo"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
                style={{ fontWeight: "bold", color: "#333" }}
              >
                Comprehensive Platform Support
              </h2>
              <p
                className="pStyle"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{ color: "#555", fontSize: "1rem", lineHeight: "1.5" }}
              >
                From Android to iOS and React Native, Beta Appstore has you
                covered. Our platform is designed to handle diverse build
                requirements, helping your team focus on innovation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="greyWrap">
        <div className="container">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-lg-5 col-md-12 text-center text-lg-start">
              <h2
                className="headStyleTwo"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                style={{ fontWeight: "bold", color: "#333" }}
              >
                Compliance and Security
              </h2>
              <p
                className="pStyle"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
                style={{ color: "#555", fontSize: "1rem", lineHeight: "1.5" }}
              >
                Ensure your builds meet security and compliance standards. Beta
                Appstore offers encrypted data storage, activity logging, and
                customizable access controls.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-lg-7 col-md-12 mt-4 mt-lg-0">
              <img
                src="assets/images/security.jpeg"
                alt="Compliance and Security"
                className="w-100 img-fluid"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{
                  objectFit: "cover",
                  maxHeight: "100%",
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="whiteWrap">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="headStyle" data-aos="fade-up">
                You’ll go a long way, for free.
              </h2>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="appBox">
                <img src="assets/images/build.png" alt="logo" />
                <h3>Build</h3>
                <h4>240 build minutes per month</h4>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="appBox">
                <img src="assets/images/distributed.png" alt="logo" />
                <h3>Distribute</h3>
                <h4>Unlimited distributions and users</h4>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="appBox">
                <img src="assets/images/30-days.png" alt="logo" />
                <h3>Test</h3>
                <h4>Free 30 day trial</h4>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="appBox">
                <img src="assets/images/analytics.png" alt="logo" />
                <h3>Analytics</h3>
                <h4>All features included</h4>
              </div>
            </div>

            <div
              className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="appBox">
                <img src="assets/images/general.png" alt="logo" />
                <h3>General</h3>
                <h4>Unlimited apps, organizations, and teams</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="darkYellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h2 className='headStyle text-left mb-4'>Find bugs on any device before release.</h2>
                            <p className='pStyle'>Wondering if your app still functions after recent changes? Curious about its performance on an LG G2? Test your app in a hosted device lab with thousands of real iOS and Android devices. You'll receive detailed test results, full-resolution screenshots of each step, and performance metrics.</p>

                            <p className='pStyle'>Validate every feature, on every device, with every commit. Welcome to the next generation of app testing.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src="assets/images/test-devices.png" alt="logo" className='w-100' />
                            
                        </div>
                    </div>
                </div>
            </div> */}
      <div id="aboutUs" style={{ padding: "20px" }}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 col-12" data-aos="fade-right">
              <img
                src="assets/images/about_us.jpeg"
                alt="About Us"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                data-aos="zoom-in"
              />
            </div>
            <div className="col-lg-7" data-aos="fade-left">
              <div className="aboutDetail">
                <h2 className="headStyle text-left mb-4 mt-4">
                  About Beta Appstore
                </h2>
                <p className="pStyle">
                  Welcome to <b>Beta Appstore</b>, your ultimate destination for
                  seamless app distribution and testing. Designed with
                  developers and testers in mind, our platform simplifies the
                  process of sharing and managing beta builds, enabling
                  on-the-fly installation of APKs and IPAs directly onto
                  devices.
                </p>
                <br />
                <h2 className="headStyle text-left mb-4">Why Beta Appstore?</h2>
                <p className="pStyle">
                  In the fast-paced world of app development, efficiency and
                  reliability are key.
                  <b> Beta Appstore</b> bridges the gap between development and
                  deployment, offering a robust platform for:
                  <br />
                  <ul>
                    <li>
                      <p>
                        <b>Easy Distribution: </b>
                        Share your app builds with team members, testers, or
                        clients effortlessly.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Device Compatibility: </b>
                        Install apps on both Android and iOS devices with
                        minimal setup.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Streamlined Testing: </b>
                        Empower testers to access the latest builds with a
                        simple link or QR code.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Automation-Ready (Coming soon): </b>
                        Integrate with CI/CD tools like GitHub Actions,
                        Bitbucket CI/CD, and CircleCI to automatically push
                        builds to the store, ensuring your team always works
                        with the latest version.
                      </p>
                    </li>
                  </ul>
                </p>
                <br />
                <h2 className="headStyle text-left mb-4">Who is it for?</h2>
                <ul>
                  <li>
                    <p>
                      <b>Developers: </b>
                      Simplify the deployment process and focus on building
                      great apps.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Testers: </b>
                      Access, test, and provide feedback on the latest builds
                      with ease.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        Project Managers / Production Owners / Mobile App
                        Owners:
                      </b>
                      Ensure smooth communication and delivery across teams.
                    </p>
                  </li>
                </ul>
                <h2 className="headStyle text-left mb-4">Key Features</h2>
                <ul>
                  <li>
                    <p>
                      <b>On-the-Air Installation: </b>
                      Install apps directly on devices without complicated
                      provisioning.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Centralized Build Management: </b>
                      Manage multiple app versions in one place.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Customisable Access Control: </b>
                      Securely share builds with select users.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Detailed Analytics: </b>
                      Gain insights into downloads, feedback, and app
                      performance.
                    </p>
                  </li>
                </ul>
                <p className="pStyle">
                  Whether you're a startup, an enterprise, or an indie
                  developer,
                  <b>Beta Appstore</b> helps you accelerate your app development
                  lifecycle, ensuring high-quality releases with every
                  iteration. Join us and redefine how you distribute and test
                  apps.
                  <b>Beta Appstore</b> is here to make your journey from code to
                  launch effortless and efficient.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>Fast Updates, Smooth Releases
                            </h2>
                            <p className='text-center pStyle'>Enhancements and fixes go live effortlessly with Beta Appstore. Whether it’s private distribution, open beta testing, or publishing to Google Play, TestFlight, or the App Store, we’ve got you covered.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <img src="assets/images/distribute.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-7">
                            <h2 className='headStyleTwo'>Promote validated builds to app stores</h2>
                            <p className='pStyle'>Once you’ve beta tested a release candidate, promote the same build directly to Intune, Google Play, or App Store Connect. Beta Appstore tracks your builds through every release.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle mb-4'>Collect real-time diagnostics,
                            prioritize and fix critical issue.
                            </h2>
                            <p className='text-center pStyle'>Integrate the Beta Appstore SDK for automatic crash reporting. Build your app in Beta Appstore, and get automatic symbol management for richer reports.
                            </p>
                            <img src="assets/images/diagnostics.png" alt="logo" className='mt-5'/>
                            <p className='pStyle mt-5'>
                            Crash reports are grouped by common cause, highlighting the relevant stack frame so you can locate errors by file and
                            line number. Search specific users' crashes and browse individual reports for event breadcrumbs and custom data attachments.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>App analytics, made for developers.</h2>
                            <p className='text-center pStyle'>Developer-friendly analytics make it easy to discover how to improve your apps.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <img src="assets/images/app-center-live-analytics@2x.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Live analytics for sessions, events, crashes, and more</h2>
                            <p className='pStyle'>See streaming activity for sessions and events immediately, and collect metrics that become more useful over time.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Who? What? Where? When? Why? How?</h2>
                            <p className='pStyle'>Is your audience growing? What devices and operating systems are most common? What features are popular? Get straightforward answers to the questions you care about.</p>
                        </div>
                        <div className="col-lg-7">
                            <img src="assets/images/analytics-graphs.png" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle mb-3'>You’re in good company.</h2>
                            <p className='pStyle'>These companies already use Beta Appstore.</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-2">
                            <div className="companyBox">
                               <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                               <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div> */}

      {env_type === "STAGING" ? (
        <div className="darkYellowWrap" id="priceDiv">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cartWrap">
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <div className="cartBox">
                        <h3>
                          Scale up with additional concurrent builds as your
                          team expands.
                        </h3>
                        <p>
                          Accelerate your app delivery by running multiple
                          builds simultaneously.
                        </p>
                        <h4>
                          &#x20b9;40<span>/month</span>
                        </h4>
                        <h5>per build concurrency</h5>
                        <a href="/#">Get Started</a>
                      </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                      <div className="cartBox">
                        <h3>
                          Run tests concurrently on a wider range of devices.
                        </h3>
                        <p>
                          Execute UI tests on thousands of real devices and
                          numerous configurations.
                        </p>
                        <h4>
                          &#x20b9;99/<span>/month</span>
                        </h4>
                        <h5>per standard device concurrency</h5>
                        <a href="/#">Get Started</a>
                      </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                      <div className="cartBox">
                        <h3>Test on more devices in parallel</h3>
                        <p>
                          Run UI test on thousands of real devices and hundreds
                          of configurations.
                        </p>
                        <h4>
                          &#x20b9;199/<span>/month</span>
                        </h4>
                        <h5>per standard device concurrency</h5>
                        <a href="/#">Get Started</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <footer className="landingFooter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <img src={logo} alt="logo" className="footerLogo mr-3" />
                <p>
                  Copyright © 2024. All rights reserved to Barquecon
                  Technologies Pvt Ltd.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="d-flex justify-content-end">
                <a href="/terms-of-use">Terms of use</a>
                <a href="/privacy-policy">Privacy & cookies</a>
                {/* <a href='/#'>Trademarks</a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
