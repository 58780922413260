import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navbar from "./Navbar";
import logo from "../../logo/Logo.svg";

const TermsOfUse = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".section-content");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.2 }
    );
    sections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, []);

  const termsSections = [
    {
      title: "1. Acceptance of Terms",
      content:
        "By accessing or using Beta Appstore ('the Service'), you agree to be bound by these Terms of Use and any future amendments. These terms constitute a legal agreement between you and Barquecon Technologies Pvt Ltd.",
    },
    {
      title: "2. Beta Service",
      content:
        "Beta Appstore is currently offered as a free version to Mobile Developers/Freelancers/Companies/Institutions who are intereseted to release their apps internally their own team mates or privately to any institutions before it goes to actual stores. The Service is provided free of charge for free feaures untill any freemium feaures are explicitly asked to take the subscriptions for. Features and functionality may change without notice during our consecutive releases and the Service may experience interruptions or data loss during the transition.",
    },
    {
      title: "3. Transition to Freemium and Premium Features",
      content:
        "Beta Appstore may transition to a freemium model, offering free features alongside premium paid features (“Premium Features”). Details of freemium and premium plans, including pricing and feature descriptions, will be communicated in advance on betaappstore.com. Continued use of the Service after such changes signifies your acceptance of the updated terms only for Paid features. However, free features will continued to remain free but with certain limits after the transition.",
    },
    {
      title: "4. User Accounts",
      content:
        "To access certain features, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
    },
    {
      title: "5. Prohibited Activities",
      content: (
        <List>
          <ListItem>
            <ListItemText primary="You agree not to:" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Use the Service for unlawful purposes or to violate any laws." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Attempt to disrupt the service’s operations or security." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Reverse-engineer, decompile, or disassemble any part of the Service." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Post or share content that is offensive, defamatory, or violates intellectual property rights." />
          </ListItem>
        </List>
      ),
    },
    {
      title: "6. Intellectual Property",
      content:
        "All content and materials on Beta Appstore, including but not limited to text, graphics, logos, and software, are the property of Barquecon Technologies Pvt Ltd or its licensors and are protected by applicable intellectual property laws. You are granted a limited, non-exclusive license to use the Service for personal or internal business purposes.",
    },
    {
      title: "7. Disclaimer of Warranties",
      content:
        "The Beta Appstore Service is provided “AS IS” without warranties of any kind, express or implied. We disclaim all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.",
    },
    {
      title: "8. Limitation of Liability",
      content:
        "To the fullest extent permitted by law, Barquecon Technologies Pvt Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.",
    },
    {
      title: "9. Termination",
      content:
        "We reserve the right to suspend or terminate your access to the Service at any time, for any reason, without prior notice in case of any misuse or misconduct or fraud activities detected from any users on the platform.",
    },
    {
      title: "10. Modifications to Terms",
      content:
        "We may update these Terms of Use from time to time. Any changes will be effective upon posting the revised terms. Your continued use of the Service after such changes constitutes your acceptance of the new terms.",
    },
    {
      title: "11. Governing Law",
      content:
        "These Terms of Use are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.",
    },
    {
      title: "12. Contact Us",
      content: (
        <>
          If you have any questions or concerns about these Terms of Use, please contact us at <a href="mailto:support@betaappstore.com" className="text-blue-500 underline">support@betaappstore.com</a>
          <br />
          Thank you for using Beta Appstore. We value your feedback as we continue to develop and improve our Service!
        </>
      ),
    },
    
  ];

  return (
    <>
      
      <AppBar position="static" sx={{ background: "#ffb700",}}>
      <Navbar env_type="STAGING" />
        <Toolbar sx={{ flexGrow: 1 , margin:"60px 0px 2px 0px" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: "'Noto Sans', sans-serif !important" }}>
            Terms of Use
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 4 }}>
        <Typography variant="body1" align="left" gutterBottom sx={{ fontFamily: "'Noto Sans', sans-serif !important" }}>
          <b>Effective Date:</b> 05th Jan, 2025 
          <br/>
        Welcome to Beta Appstore! By accessing or using our platform, you agree to the following Terms of Use. Please read them carefully. If you do not agree to these terms, you may not use the Beta Appstore.
        </Typography>

        {termsSections.map((section, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              p: 3,
              my: 2,
              borderLeft: "5px solid #ffb700",
              background: "#ffffff",
              fontFamily: "'Noto Sans', sans-serif !important",
            }}
            className="section-content"
          >
            <Typography variant="h5" sx={{ mb: 2, color: "#002855", fontFamily: "'Noto Sans', sans-serif !important" }}>
              {section.title}
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: "'Noto Sans', sans-serif !important" }}>{section.content}</Typography>
          </Paper>
        ))}
      </Container>

      <footer className="landingFooter">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center">
                      <img src={logo} alt="logo" className="footerLogo mr-3" />
                      <p>Copyright © 2025. All rights reserved to Barquecon Technologies Pvt Ltd.</p>
                    </div>
                  </div>
      
                  <div className="col-lg-5">
                    <div className="d-flex justify-content-end">
                    <a href='/privacy-policy'>Privacy Policy</a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
    </>
  );
};

export default TermsOfUse;
